import React from 'react';
import { navbarStyles as classes } from './navbarStyles';
import { NavbarDropDownV2 } from '@models/navigationBar';
import { Text } from '@web-for-marketing/react-ui';
import { Divider } from '@mui/material';
import { DropdownMenuColumns } from './DropdownMenuColumns';
import { useAtom } from 'jotai';
import { selectedNavbarDropdownAtom } from '@atoms/navigation';
import { keyIsEnter, keyIsSpace } from '@helpers/keyPressEventHelper';

interface DropdownMenuProps {
    content: NavbarDropDownV2['content'];
    preview?: boolean;
    shrink?: boolean;
}

export function DropdownMenu({ content, preview, shrink }: DropdownMenuProps): JSX.Element {
    const [selected, setSelected] = useAtom(selectedNavbarDropdownAtom);
    const open = selected === content.title;

    function showDropdown(): void {
        setSelected(content.title);
    }

    function hideDropdown(): void {
        setSelected('');
    }

    function toggleDropdown(event: React.KeyboardEvent): void {
        if (keyIsEnter(event) || keyIsSpace(event)) {
            if (!open) {
                showDropdown();
            } else {
                hideDropdown();
            }
        }
    }

    return (
        <div onMouseEnter={!preview ? showDropdown : undefined} onMouseLeave={!preview ? hideDropdown : undefined}>
            <button
                css={[
                    classes.mainLeftItem,
                    classes.underlineMenuItem,
                    classes.dropdownButton,
                    open ? classes.openDropdownButton : '',
                ]}
                aria-expanded={open}
                onKeyDown={toggleDropdown}
                tabIndex={preview ? -1 : 0}
            >
                <Text
                    variant='body2'
                    color={open ? 'activeSecondary' : 'tertiary'}
                    css={shrink ? classes.smallMainText : undefined}
                >
                    {content.title}
                </Text>
            </button>
            {!preview ? (
                <div
                    aria-hidden={!open}
                    css={[classes.dropdown, open ? classes.showOpen : '']}
                    data-testid='navbarMenuDropdown'
                >
                    <Divider light />
                    <DropdownMenuColumns columns={content.columns} open={open} />
                </div>
            ) : null}
        </div>
    );
}
