import React, { startTransition } from 'react';
import { navbarStyles as classes } from './navbarStyles';
import { languageRouteTypes } from '@helpers/languageRoutes';
import { Toolbar } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { NavbarMicroContent, MenuNavigationV2 } from '@models/navigationBar';
import { isAuthenticatedAtom } from '@atoms/authentication';
import { UserMenuDropdown } from '@pages/admin/Navbar/UserMenuDropdown';
import { UserMenuOption } from '@models/adminUserMenu';
import { CMSLink } from '@components/CMSLink';
import { getLinkTargetValue } from '@sections/helpers/SectionComponentHelper';
import { LanguageCode } from '@models/languageCode';
import { NewTabIcon } from '@icon/NewTabIcon';
import { SelectedLanguage } from '@components/RegionSelection/SelectedLanguage';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { isRegionSelectionDialogOpenAtom } from '@atoms/appSettings';

interface MicroMenuProps {
    microContent: NavbarMicroContent;
    showLogin?: MenuNavigationV2['showLoginButton'];
    loginText?: MenuNavigationV2['loginText'];
    loginUrl?: MenuNavigationV2['loginUrl'];
    showLanguageRegion?: MenuNavigationV2['showLanguageRegion'];
    preview?: boolean;
    shrink?: boolean;
    language: LanguageCode;
}

export function MicroMenu({
    microContent,
    showLanguageRegion,
    showLogin,
    preview,
    shrink,
    language,
    loginText,
    loginUrl,
}: MicroMenuProps): JSX.Element {
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const setIsRegionSelection = useSetAtom(isRegionSelectionDialogOpenAtom);

    return (
        <Toolbar disableGutters css={classes.microNav(shrink, preview)} data-testid='navMicroMenu'>
            <div css={classes.microContent(shrink)}>
                {microContent.left.map((leftContent, index) => (
                    <CMSLink
                        css={classes.microLeftLink(shrink)}
                        key={`${JSON.stringify(leftContent.content)}-${index}`}
                        href={leftContent.content.href}
                        target={getLinkTargetValue(leftContent.content.isTargetToNewTab)}
                        tabIndex={preview ? -1 : 0}
                    >
                        {leftContent.content.title}
                        {leftContent.content.isTargetToNewTab ? (
                            <NewTabIcon aria-hidden='true' css={classes.smallLeftMarginIcon} />
                        ) : null}
                    </CMSLink>
                ))}
            </div>
            <div css={[classes.microContent(shrink), classes.microRight]}>
                {microContent.right.map((rightContent, index) => (
                    <CMSLink
                        css={classes.microRightLink(shrink)}
                        key={`${JSON.stringify(rightContent.content)}-${index}`}
                        href={rightContent.content.href}
                        target={getLinkTargetValue(rightContent.content.isTargetToNewTab)}
                        tabIndex={preview ? -1 : 0}
                    >
                        {rightContent.content.title}
                        {rightContent.content.isTargetToNewTab ? (
                            <NewTabIcon aria-hidden='true' css={classes.smallLeftMarginIcon} />
                        ) : null}
                    </CMSLink>
                ))}
                {showLogin ? (
                    <a
                        href={
                            loginUrl ||
                            getLanguageRouteAndPrefixByCodeAndRouteKey(language, languageRouteTypes.MY_GEOTAB_LOGIN)
                        }
                        css={classes.loginButton(shrink)}
                        tabIndex={preview ? -1 : 0}
                    >
                        {loginText ? loginText : 'Login'}
                    </a>
                ) : null}
                {showLanguageRegion ? (
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        <span css={classes.languageRegionDivider(shrink)} />
                        <button
                            css={classes.languageButton(shrink)}
                            tabIndex={preview ? -1 : 0}
                            onClick={
                                !preview
                                    ? () => {
                                        startTransition(() => {
                                            setIsRegionSelection(true);
                                        });
                                    }
                                    : undefined
                            }
                            data-testid='microMenuLanguageRegion'
                        >
                            <SelectedLanguage languageCode={language} />
                        </button>
                    </div>
                ) : null}
                {isAuthenticated && !preview ? (
                    <div>
                        <UserMenuDropdown
                            size='small'
                            dropdownOptions={[UserMenuOption.Dashboard, UserMenuOption.History, UserMenuOption.Logout]}
                        />
                    </div>
                ) : null}
            </div>
        </Toolbar>
    );
}
