import React, { useEffect, useRef } from 'react';
import { MenuNavigationV2 } from '@models/navigationBar';
import { AppBar, Divider } from '@mui/material';
import { useAtom } from 'jotai';
import { navbarStyles as classes } from './navbarStyles';
import { MicroMenu } from './MicroMenu';
import { MainMenu } from './MainMenu';
import { isSubNavigationFixedPositionAtom, navHeightAtom, remSizeAtom } from '@atoms/appSettings';
import { getLanguageById } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';

interface NavbarProps {
    navbar: MenuNavigationV2;
    preview?: boolean;
    shrink?: boolean;
}

export function Navbar({ navbar, preview, shrink }: NavbarProps): JSX.Element {
    const [navHeight, setNavHeight] = useAtom(navHeightAtom);
    const [remSize] = useAtom(remSizeAtom);
    const [isSubNavigationFixedPosition] = useAtom(isSubNavigationFixedPositionAtom);
    const menuNav = useRef<HTMLDivElement>(null);
    const getNavHeight = (): number | null => menuNav && menuNav.current && menuNav.current.clientHeight;
    const language = getLanguageById(navbar.language)?.code || LanguageCode.en;

    useEffect(() => {
        const currentNavHeight = getNavHeight();

        if (currentNavHeight && currentNavHeight != navHeight) {
            setNavHeight(currentNavHeight);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuNav.current]);

    return (
        <div
            ref={menuNav}
            css={[classes.stickyNavbar, preview ? classes.preview : undefined]}
            style={remSize >= 14 ? { position: 'relative' } : undefined}
            data-testid='navbarV2'
        >
            <AppBar id='NavigationBar' aria-label='Main navigation' css={classes.header}>
                {!isSubNavigationFixedPosition && navbar.isMicroNavVisible ? (
                    <MicroMenu
                        microContent={navbar.microContent}
                        showLogin={navbar.showLoginButton}
                        showLanguageRegion={navbar.showLanguageRegion}
                        preview={preview}
                        shrink={shrink}
                        language={language}
                        loginText={navbar.loginText}
                        loginUrl={navbar.loginUrl}
                    />
                ) : null}
                <MainMenu
                    mainContent={navbar.mainContent}
                    secondaryLogo={navbar.secondaryLogo}
                    secondaryLogoLink={navbar.secondaryLogoLink}
                    secondaryLogoLabel={navbar.secondaryLogoLabel}
                    secondaryLogoAlt={navbar.secondaryLogoAlt}
                    showSearch={navbar.showSearch}
                    preview={preview}
                    shrink={shrink}
                />
            </AppBar>
            <Divider css={{ opacity: 0.6 }} />
        </div>
    );
}
