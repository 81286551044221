import React, { useEffect, useRef } from 'react';
import { navbarStyles as classes } from './navbarStyles';
import { NavbarDropDownV2 } from '@models/navigationBar';
import { Text, ForwardIcon } from '@web-for-marketing/react-ui';
import { CMSLink } from '@components/CMSLink';
import { Picture } from '@components/Picture';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { getLinkTargetValue } from '@sections/helpers/SectionComponentHelper';
import { NewTabIcon } from '@icon/NewTabIcon';

interface DropdownMenuColumnsProps {
    columns: NavbarDropDownV2['content']['columns'];
    open?: boolean;
    preview?: boolean;
}

export function DropdownMenuColumns({ columns, open, preview }: DropdownMenuColumnsProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const dropdown = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (columns.length) {
            const images: NodeListOf<HTMLImageElement> | undefined =
                dropdown.current?.querySelectorAll('img[data-src]');
            if (open && images?.length) {
                images.forEach((image) => {
                    if (image.dataset && image.dataset.src) {
                        image.setAttribute('src', image.dataset.src);
                        image.removeAttribute('data-src');
                    }
                });
            }
        }
    }, [open, columns]);

    return (
        <div
            css={[classes.columns(preview), preview ? classes.preview : undefined]}
            data-testid='dropdownMenuColumns'
            ref={dropdown}
        >
            <div css={classes.columnsContainer}>
                {columns.map((column, index) => {
                    return (
                        <div key={`${JSON.stringify(column)}-${index}`} css={classes.column}>
                            {column.map((columnContent, index) => {
                                switch (columnContent.type) {
                                    case 'heading':
                                        return (
                                            <Text
                                                key={`${JSON.stringify(columnContent.content)}-${index}`}
                                                variant='body2'
                                                color='tertiary'
                                                weight='bold'
                                            >
                                                {columnContent.content.title}
                                                {columnContent.content.lines
                                                    ? Array.from(Array(columnContent.content.lines).keys()).map((v) => (
                                                        <br data-testid='heading-column-line-break' key={v} />
                                                    ))
                                                    : null}
                                            </Text>
                                        );
                                    case 'link':
                                        return (
                                            <CMSLink
                                                key={`${JSON.stringify(columnContent.content)}-${index}`}
                                                href={columnContent.content.href}
                                                target={getLinkTargetValue(columnContent.content.isTargetToNewTab)}
                                                css={classes.columnLink}
                                                tabIndex={!open || preview ? -1 : 0}
                                            >
                                                {columnContent.content.title}
                                                {columnContent.content.isTargetToNewTab ? (
                                                    <NewTabIcon
                                                        aria-hidden='true'
                                                        css={[classes.leftMarginIcon, classes.iconAlignment]}
                                                    />
                                                ) : (
                                                    <ForwardIcon css={classes.columnLinkIcon} />
                                                )}
                                            </CMSLink>
                                        );
                                    case 'dropdownLink':
                                        return (
                                            <CMSLink
                                                key={`${JSON.stringify(columnContent.content)}-${index}`}
                                                href={columnContent.content.href}
                                                target={getLinkTargetValue(columnContent.content.isTargetToNewTab)}
                                                css={classes.dropdownLink}
                                                tabIndex={!open || preview ? -1 : 0}
                                            >
                                                <div css={classes.dropdownLinkContent}>
                                                    {columnContent.content.icon.imagePath ? (
                                                        <img
                                                            src=''
                                                            data-src={prefixStringWithBaseUrl(
                                                                columnContent.content.icon.imagePath
                                                            )}
                                                            alt={columnContent.content.icon.imageAlt}
                                                            width='40px'
                                                            height='40px'
                                                            css={classes.dropdownLinkIcon}
                                                        />
                                                    ) : null}
                                                    <div>
                                                        <Text color='primary'>
                                                            {columnContent.content.title}
                                                            {columnContent.content.isTargetToNewTab ? (
                                                                <NewTabIcon
                                                                    aria-hidden='true'
                                                                    css={classes.smallLeftMarginIcon}
                                                                />
                                                            ) : null}
                                                        </Text>
                                                        {columnContent.content.description ? (
                                                            <Text
                                                                variant='body2'
                                                                color='secondary'
                                                                css={{ marginTop: '0.4rem' }}
                                                            >
                                                                {columnContent.content.description}
                                                            </Text>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </CMSLink>
                                        );
                                    case 'callToAction':
                                        return (
                                            <CMSLink
                                                key={`${JSON.stringify(columnContent.content)}-${index}`}
                                                href={columnContent.content.href}
                                                target={getLinkTargetValue(columnContent.content.isTargetToNewTab)}
                                                css={classes.cta}
                                                tabIndex={!open || preview ? -1 : 0}
                                            >
                                                <Picture
                                                    alt={columnContent.content.imageAlt}
                                                    lazyLoad={true}
                                                    imagePath={columnContent.content.imagePath}
                                                    css={classes.ctaImage}
                                                />
                                                <Text variant='h4' css={classes.ctaTextSpacing}>
                                                    {columnContent.content.headingText}
                                                </Text>
                                                <Text variant='body2' color='primary' css={classes.ctaTextSpacing}>
                                                    {columnContent.content.description}
                                                </Text>
                                                <Text
                                                    variant='body2'
                                                    color='activeSecondary'
                                                    weight='bold'
                                                    css={classes.ctaLinkText}
                                                >
                                                    {columnContent.content.linkText}
                                                    {columnContent.content.isTargetToNewTab ? (
                                                        <NewTabIcon aria-hidden='true' css={classes.leftMarginIcon} />
                                                    ) : (
                                                        <ForwardIcon height={12} width={12} css={classes.ctaLinkIcon} />
                                                    )}
                                                </Text>
                                            </CMSLink>
                                        );
                                    case 'smallLink':
                                        return (
                                            <CMSLink
                                                key={`${JSON.stringify(columnContent.content)}-${index}`}
                                                href={columnContent.content.href}
                                                target={getLinkTargetValue(columnContent.content.isTargetToNewTab)}
                                                css={classes.dropdownLink}
                                                tabIndex={!open || preview ? -1 : 0}
                                            >
                                                <Text variant='body2' color='primary'>
                                                    {columnContent.content.title}
                                                    {columnContent.content.isTargetToNewTab ? (
                                                        <NewTabIcon
                                                            aria-hidden='true'
                                                            css={classes.smallLeftMarginIcon}
                                                        />
                                                    ) : null}
                                                </Text>
                                            </CMSLink>
                                        );
                                    default:
                                        break;
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
