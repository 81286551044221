import React from 'react';
import { navbarStyles as classes } from './navbarStyles';
import { Toolbar } from '@mui/material';
import { CMSLink } from '@components/CMSLink';
import { GeotabWithR } from '@icon/GeotabWithR';
import { PublicSearchDropdown } from '@components/PublicSearch/PublicSearchDropdown';
import { getLanguageUrlPrefixByCode } from '@helpers/languages';
import { Text } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { isAuthenticatedAtom } from '@atoms/authentication';
import { NavbarMainContent, MenuNavigationV2 } from '@models/navigationBar';
import { DropdownMenu } from './DropdownMenu';
import { getLinkTargetValue } from '@sections/helpers/SectionComponentHelper';
import { NewTabIcon } from '@icon/NewTabIcon';

interface MainMenuProps {
    mainContent: NavbarMainContent;
    secondaryLogo?: MenuNavigationV2['secondaryLogo'];
    secondaryLogoLink?: MenuNavigationV2['secondaryLogoLink'];
    secondaryLogoLabel?: MenuNavigationV2['secondaryLogoLabel'];
    secondaryLogoAlt?: MenuNavigationV2['secondaryLogoAlt'];
    showSearch?: MenuNavigationV2['showSearch'];
    preview?: boolean;
    shrink?: boolean;
}

export function MainMenu({
    mainContent,
    secondaryLogo,
    secondaryLogoLink,
    secondaryLogoAlt,
    secondaryLogoLabel,
    showSearch,
    preview,
    shrink,
}: MainMenuProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);

    return (
        <Toolbar disableGutters css={classes.mainNav(shrink)}>
            <div css={classes.mainContentLeft}>
                <div css={classes.logosContainer}>
                    <CMSLink
                        href={!isAuthenticated ? getLanguageUrlPrefixByCode(selectedLanguage) : '/admin/dashboard/'}
                        aria-label='Go to Geotab Homepage'
                        type='link'
                        spaLink={isAuthenticated}
                        css={[classes.underlineMenuItem, classes.logo(secondaryLogo ? true : false, shrink)]}
                        tabIndex={preview ? -1 : 0}
                    >
                        <GeotabWithR css={[classes.logoImage]} />
                    </CMSLink>
                    {secondaryLogo ? (
                        <>
                            <div css={classes.logodDivider} data-testid='logoSeparator' />
                            {secondaryLogoLink ? (
                                <CMSLink
                                    href={secondaryLogoLink}
                                    aria-label={secondaryLogoLabel || ''}
                                    css={[classes.underlineMenuItem, classes.logo(true, shrink)]}
                                    data-testid='seondaryLogoLink'
                                    tabIndex={preview ? -1 : 0}
                                >
                                    <img
                                        src={prefixStringWithBaseUrl(secondaryLogo)}
                                        alt={secondaryLogoAlt || ''}
                                        css={classes.logoImage}
                                    />
                                </CMSLink>
                            ) : (
                                <div css={classes.logo(true, shrink)} data-testid='seondaryLogoNoLink'>
                                    <img
                                        src={prefixStringWithBaseUrl(secondaryLogo)}
                                        alt={secondaryLogoAlt || ''}
                                        css={classes.logoImage}
                                    />
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
                <div css={classes.mainLeftContent(secondaryLogo ? true : false, shrink)}>
                    {mainContent.left.map((leftContent, index) => {
                        switch (leftContent.type) {
                            case 'dropdown':
                                return (
                                    <DropdownMenu
                                        key={`${JSON.stringify(leftContent.content)}-${index}`}
                                        content={leftContent.content}
                                        preview={preview}
                                        shrink={shrink}
                                    />
                                );
                            case 'link':
                                return (
                                    <CMSLink
                                        key={`${JSON.stringify(leftContent.content)}-${index}`}
                                        href={leftContent.content.href}
                                        target={getLinkTargetValue(leftContent.content.isTargetToNewTab)}
                                        css={[classes.mainLeftItem, classes.underlineMenuItem]}
                                        tabIndex={preview ? -1 : 0}
                                    >
                                        <Text
                                            variant='body2'
                                            color='tertiary'
                                            css={shrink ? classes.smallMainText : undefined}
                                        >
                                            {leftContent.content.title}
                                            {leftContent.content.isTargetToNewTab ? (
                                                <NewTabIcon aria-hidden='true' css={classes.smallLeftMarginIcon} />
                                            ) : null}
                                        </Text>
                                    </CMSLink>
                                );
                            default:
                                break;
                        }
                    })}
                </div>
            </div>
            <div css={classes.mainRightContent(shrink)}>
                {mainContent.right.map((rightContent, index) => {
                    switch (rightContent.type) {
                        case 'popoutButton':
                            return (
                                <PopoutButton
                                    key={`${JSON.stringify(rightContent.content)}-${index}`}
                                    text={rightContent.content.title}
                                    variant={rightContent.content.variant}
                                    customSectionKey={rightContent.content.customSection}
                                    params={rightContent.content.params}
                                    css={classes.mainRightButton(shrink)}
                                    tabIndex={preview ? -1 : 0}
                                />
                            );
                        case 'buttonLink':
                            return (
                                <CMSLink
                                    key={`${JSON.stringify(rightContent.content)}-${index}`}
                                    type='button'
                                    href={rightContent.content.href}
                                    variant={rightContent.content.variant}
                                    target={getLinkTargetValue(rightContent.content.isTargetToNewTab)}
                                    css={classes.mainRightButton(shrink)}
                                    tabIndex={preview ? -1 : 0}
                                >
                                    {rightContent.content.title}
                                    {rightContent.content.isTargetToNewTab ? (
                                        <NewTabIcon aria-hidden='true' css={classes.smallLeftMarginIcon} />
                                    ) : null}
                                </CMSLink>
                            );
                        default:
                            break;
                    }
                })}
                {showSearch ? (
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        <span css={classes.searchDivider} />
                        <PublicSearchDropdown preview={preview} />
                    </div>
                ) : null}
            </div>
        </Toolbar>
    );
}
